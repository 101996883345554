<template>
  <div class="auth">
    <div class="container">
      <AuthForm />
    </div>
  </div>
</template>

<script>
import AuthForm from '@/components/Auth/AuthForm.vue';

export default {
  components: {
    AuthForm,
  },
};
</script>

<style lang="sass" scoped>
.auth
  position: relative
  z-index: 2
</style>
