<template>
  <form class="auth-form" novalidate @submit.prevent @keyup.enter.prevent>
    <h1 class="auth-form__title">Sign in</h1>
    <div class="auth-form__subtitle text">
      Please input your information in the fields below to enter your platform.
    </div>
    <div class="auth-form__items">
      <div class="auth-form__item">
        <LabelField id="email" class="auth-form__label" title="E-Mail" />
        <TextField
          id="email"
          v-model.trim="email"
          name="email"
          required
          class="auth-form__input"
          type="text"
          :error="isEmailError"
          :error-txt="emailErrorText"
          tooltip-text=""
          @focus="resetServerError('email')"
        />
      </div>
      <div class="auth-form__item">
        <LabelField id="password" class="auth-form__label" title="Password" />
        <TextField
          id="password"
          v-model.trim="password"
          name="password"
          required
          class="auth-form__input"
          type="password"
          is-password
          :password-strength="passwordStrength"
          :error="isPasswordError"
          :error-txt="passwordErrorText"
          tooltip-text="Password must be at least 8 characters with 1 upper case letter and 1 number."
          @focus="resetServerError('password')"
        />
      </div>
    </div>
    <a
      href="#"
      class="auth-form__caption"
      @click.prevent="forgotPasswordHandler"
    >
      Forgot your password?
    </a>
    <div class="auth-form__controls">
      <ButtonBase :loading="signInLoader" @click="authHandler"
        >Sign in</ButtonBase
      >
    </div>
  </form>
</template>

<script>
import authValidation from '@/mixins/validation/forms/auth.js';
import LabelField from '@/elements/LabelField';

export default {
  components: {
    LabelField,
  },
  mixins: [authValidation],
  data() {
    return {
      signInLoader: false,
      email: '',
      password: '',
      loadingSubmitBtn: false,
    };
  },
  methods: {
    async authHandler() {
      if (this.isInvalidForm) {
        this.showValidationErrors();
        return;
      }
      this.signInLoader = true;
      await this.$store.dispatch('authorization', {
        email: this.email,
        password: this.password,
      });
      this.signInLoader = false;
    },
    async forgotPasswordHandler() {
      if (this.$v.email.$invalid) {
        this.$v.email.$touch();
        return;
      }
      await this.$store.dispatch('resetPassword', {
        email: this.email,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.auth-form__subtitle
  margin-top: 12px
  margin-bottom: 64px
  @include ms
    margin-top: 4px
    margin-bottom: 40px

.auth-form__items
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 24px 40px
  @include ms
    grid-template-columns: 1fr

.auth-form__caption
  display: inline-block
  margin-top: 10px
  font-weight: 500
  @include adaptive-font-size(14, 14, 12)
  @include adaptive-line-height(24, 24, 18)
  @include ms
    margin-top: 30px

  &:hover
    text-decoration: underline

.auth-form__controls
  display: flex
  justify-content: flex-end
  margin-top: 64px
  @include ms
    margin-top: 40px
</style>
